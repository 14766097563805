
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Carousel from './components/Carousel';
import Header from './components/Header';
import Leadership from './components/Leadership';
import Home from './components/Home';
import Projects from './components/Projects';
import Counsil from './components/Counsil';
import Confrence from './components/Confrence';
import Talent from './components/Talent';
import Footer from './components/Footer';
import General from './components/Docgeneral';
import News from './components/News';
import ConferenceFull from './components/ConferenceFull';
import NewsFull from './components/NewsFull';
import ProjectFull from './components/ProjectFull';
import TalentFull from './components/TalentFull';
import './App.css';
import { IlmiyMuasFull, IlmiyMuassasa } from './components/IlmiyMuass';

function App() {
  return (
    <BrowserRouter>
    <Header/>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/carousel' element={<Carousel/>}/>
    <Route path='/leader' element={<Leadership/>}/>
    <Route path='/loyiha/:id' element={<Projects/>}/>
    <Route path='/projectfull' element={<ProjectFull/>}/>
    <Route path='/council' element={<Counsil/>}/>
    <Route path='/confrence' element={<Confrence/>}/>
    <Route path='/conferencefull' element={<ConferenceFull/>}/>
    <Route path='/talent' element={<Talent/>}/>
    <Route path='/talentfull' element={<TalentFull/>}/>
    {/* <Route path='/footer' element={<Footer/>}/> */}
    <Route path='/doc_generel' element={<General/>}/>
    <Route path='/newsAll' element={<News/>}/>
    <Route path='/newsFull' element={<NewsFull/>}/>
    <Route path='/ilmiy_muass' element={<IlmiyMuassasa/>}/>
    <Route path='/ilmiy_muassfull' element={<IlmiyMuasFull/>}/>
    
    </Routes> 
    <Footer/>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import "./Partner.css";
// import "./Cards.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Talent = () => {

    const [talent, setTalents] = useState([]);
    const lang = localStorage.getItem("lang") || "uz";
    useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/talentfull/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("talent");
        setTalents(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);
  AOS.init();
  AOS.refresh();

  return (
    <div>
        <div style={{height: "90px" }}></div>
        <div class="recent_news_area section__padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="section_title text-center mb-70">
                        <h3 class="mb-45">Iqtidorli talabalar</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                 {talent.reverse().map((items) => (
                <div class="col-md-4" key={items.id}>
                    <div class="single__news">
                        <div class="thumb">
                            <a href="single-blog.html">
                                <img src={items.img} alt="" style={{maxHeight: "350px"}}/>
                            </a>
                            <span class="badge">Group Study</span>
                        </div>
                        <div class="news_info">
                            <a href={`/talentfull?name=${items.name}`}>
                                <h4>
                                    {items.name.length > 70
                            ? `${items.name.substring(0, 70)}...`
                            : items.name}
                             </h4>
                            </a>
                            <p class="d-flex align-items-center"> <span><i class="flaticon-calendar-1"></i> May 10, 2020</span> 
                            
                            <span> <i class="flaticon-comment"></i> 01 comments</span>
                            </p>
                        </div>
                    </div>
                </div>
                 ))}
               
            </div>
            
        </div>
    </div>
    </div>
  )
}

export default Talent